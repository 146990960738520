import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function AuthenticateRoutes() {

  const isAuth = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/67995704cbdebb788155e2fa')
    }
  }, [])


  return isAuth ? <Outlet /> : <Navigate to="/" />;
}
