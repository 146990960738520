import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import Navigation from './Router';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import { SAVE_USER_INFO } from './Constants';
import 'react-tooltip/dist/react-tooltip.css';
import { getPlayerById, updateBanStatus } from './Api/services/players';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getTheme } from './Api/services/theme';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);

  const fetchTheme = async () => {
    const payload = await getTheme()
    if (payload) {
      dispatch({ type: "SET_THEME", payload: payload })
    }

  }
  useEffect(() => {
    fetchTheme()
  }, [])

  const getUserByID = async () => {
    let localUser = JSON.parse(localStorage.getItem('user'));

    if (localUser && !user?.uid) {
      let uid = localUser?.socialAuth ? localUser?.uid : localUser?.uid;
      let data = await getPlayerById(uid);
      if (data?.banStatus === 'banned' && new Date(data?.banUpliftDate) < new Date()) {
        await updateBanStatus(uid)
        data = await getPlayerById(uid);
        // Dispatch the updated user data
        dispatch({ type: SAVE_USER_INFO, payload: data });
      } else {
        // Dispatch the fetched user data
        dispatch({ type: SAVE_USER_INFO, payload: data });
      }
    }
    setIsLoading(false); // Stop loading after fetching user data
  };



  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUserByID();
    } else {
      setIsLoading(false); // No token, stop loading

    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId='282837265984-qd7qjnihqhuntjcbvafpub2o1k1pe3s0.apps.googleusercontent.com'>
          <ToastContainer />
          <Navigation />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
