import { useSelector } from 'react-redux';
import classes from './Loader.module.css'; // Adjust this import as per your setup

const Loader = () => {
    const theme = useSelector(state => state.theme);

    return (
        <div className={classes.loaderContainer}>
            <div className={classes["lds-ring"]}>
                <div
                    style={{
                        borderColor: `${theme?.primary_color} transparent transparent transparent`
                    }}
                ></div>
                <div
                    style={{
                        borderColor: `${theme?.primary_color} transparent transparent transparent`
                    }}
                ></div>
                <div
                    style={{
                        borderColor: `${theme?.primary_color} transparent transparent transparent`
                    }}
                ></div>
                <div
                    style={{
                        borderColor: `${theme?.primary_color} transparent transparent transparent`
                    }}
                ></div>
            </div>
        </div>
    );
}

export default Loader;
