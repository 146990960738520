import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { UserReducer } from "./Reducers/UserReducer";
import { SignUpReducer } from "./Reducers/SignUpReducer";
import { LoginReducer } from "./Reducers/LoginReducer";
import { DashboardReducer } from "./Reducers/DashboardReducer";
import { BookingReducer } from "./Reducers/Booking";
import { ThemeReducer } from "./Reducers/Theme";
import logo from "../assets/logo/logoBlue.png"
import curve from "../assets/logo/curve.jpg"
import profile from "../assets/logo/profileBackdrop.jpg"
import bg from "../assets/bg-art.png"

const INITIAL_STATE = {
    user: null,
    theme: {
        logo_url: logo,
        profile_backDrop: profile,
        primary_color: "#007bff",
        login_bg: "#003d80",
        curve: curve,
        secondary_color: "#5bc0de", // A softer cyan-like blue
        hover_color: "#0056b3", // A darker blue for hover effects

    }, // Hardcoded theme state

};

const reducer = combineReducers({
    user: UserReducer,
    signup: SignUpReducer,
    login: LoginReducer,
    dashboard: DashboardReducer,
    booking: BookingReducer,
    theme: ThemeReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    INITIAL_STATE,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;