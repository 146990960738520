import api from "..";

export const verifyProfile = async (formData) => {
    try {
        const response = await api.post('/User/verify', formData);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

// export const getPlayerById = async (id) => {
//     try {
//         const response = await api.get(`/User/${id}`);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// }

export const getPlayerById = async (id) => {
    if (!id) {
        console.error('ID is required')
        return
    }

    try {
        const response = await api.get(`/User/${id}`);
        return response.data;
    } catch (error) {
        console.log("error", error);
    }
}

export const checkUsername = async (username) => {
    try {
        const response = await api.post('/check-username', { userName: username });
        return response?.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPlayersByUsername = async (username) => {
    try {
        const response = await api.get(`/filter-user?q=${username}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const updatePlayer = async (uid, body) => {
    try {
        const response = await api.put(`/User/${uid}`, body);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const ScanPlayer = async (data) => {
    try {
        const response = await api.post(`/scan-player`, data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getAllAdminsWithPitches = async () => {
    try {
        const response = await api.get(`/all/Users?role=admin`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const updateBanStatus = async (id) => {
    try {
        const response = await api.put(`/entity/unban/${id}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPitchesById = async (venueId) => {
    try {
        const response = await api.get(`/admin/pitch?venue=${venueId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getProductsByVenueId = async (venueId) => {
    try {
        const response = await api.get(`/products/${venueId}/all`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getCoachsByVenueId = async (venueId) => {
    try {
        const response = await api.get(`/coach/${venueId}/all`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPackagesByVenueId = async (venueId) => {
    try {
        const response = await api.get(`/package/${venueId}/all`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getProductsById = async (productsId) => {
    try {
        const response = await api.get(`/products/${productsId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getMembershipsById = async (membershipId) => {
    try {
        const response = await api.get(`/membership/${membershipId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPackagesById = async (packageId) => {
    try {
        const response = await api.get(`/package/${packageId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getClassById = async (classId) => {
    try {
        const response = await api.get(`/classes/${classId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getCoachById = async (coachId) => {
    try {
        const response = await api.get(`/coach/${coachId}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getClassesByVenueId = async (venueId) => {
    try {
        const response = await api.get(`/classes/${venueId}/all`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getMembershipsByVenueId = async (venueId) => {
    try {
        const response = await api.get(`/membership/${venueId}/all`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

export const getPlayerByIdMultiVenue = async (id) => {
    if (!id) {
        console.error('ID is required')
        return
    }
    try {
        // const response = await api.get(`/venue/${id}`);
        // console.log(response.data,response.data)
        return {
            createdAt: "2025-01-29T13:12:21.371Z",
            createdBy: "679a29350a758c2255efab0c",
            name: "Ahdaaf Al Quoz",
            sports: ["football", "padel"],
            updatedAt: "2025-01-29T13:12:21.371Z",
            _id: "67995704cbdebb788155e2fa"
        };;

    } catch (error) {
        console.log("error", error);
    }
}