import api from "..";

export const getTheme = async () => {
    try {
        const response = await api.get(`/settings/679392476d42bb4704f21245`);
        const data = response?.data?.meta
        const payload = {
            logo_url: data?.logo,
            profile_backDrop: data?.profileBackdrop,
            primary_color: data?.primaryColor,
            login_bg: data?.loginBackground,
            curve: data?.loginBackdrop,
            secondary_color: data?.secondaryColor, // A softer cyan-like blue
            hover_color: data?.hoverColor, // A darker blue for hover effects    
        }
        return payload
    } catch (error) {
        console.log(error);
    }
}
