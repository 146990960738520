import logo from "../../assets/logo/logoBlue.png"
import curve from "../../assets/logo/curve.jpg"
import profile from "../../assets/logo/profileBackdrop.jpg"

// Initial State for the Theme
const initialState = {
    logo_url: logo,
    profile_backDrop: profile,
    primary_color: "#007bff",
    login_bg: "#003d80",
    curve: curve,
    secondary_color: "#5bc0de", // A softer cyan-like blue
    hover_color: "#0056b3", // A darker blue for hover effects

};

// Theme Reducer
export const ThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_THEME":
            return action.payload; // Update the theme with the payload
        default:
            return state;
    }
};
