import React, { useState, useEffect, useCallback } from "react";
import { CButton, CImage } from "@coreui/react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import classes from "./PlayerSearch.module.css";
// import Avatar from "../../assets/Avatar.png";
import { getPlayersByUsername } from "../../Api/services/players";
import { stringAvatar } from "../../Utils";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function PlayerSelector({
  ActionButtonText,
  onAction,
  disabled,
  disabledText,
  val = 0,
}) {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useSelector((state) => state.theme);
  const fetchPlayers = async (query) => {
    try {
      const data = await getPlayersByUsername(query);

      if (data?.length === 0) {
        // check if query is an email using regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(query)) {
          setPlayers([{ label: query, value: query, image: Avatar }]);
          return;
        }
      }

      const formattedData = data?.users?.map((player) => {
        return {
          label: player.userName,
          value: player._id,
          image: player.avatar,
        };
      });
      let groupPlayers = [];
      const formattedGroupData =
        data?.groups?.map((group) => {
          group?.users?.forEach((user) => {
            groupPlayers.push({
              label: user.userName || user.name,
              value: user._id,
              image: user.avatar,
            });
          });
        }) || [];
      setPlayers([...(formattedData || []), ...groupPlayers]);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  // Debounced version of the fetchPlayers function
  const debouncedFetchPlayers = useCallback(debounce(fetchPlayers, 300), []);

  useEffect(() => {
    if (searchQuery) {
      debouncedFetchPlayers(searchQuery);
    }
  }, [searchQuery, debouncedFetchPlayers]);

  useEffect(() => {
    fetchPlayers("");
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}>
        <Autocomplete
          disabled={disabled}
          size="small"
          id="players"
          options={players}
          getOptionLabel={(option) => option.label || option}
          style={{
            width: "80%",
            background: "#f6f6f6",
            border: "none",
            borderRadius: 8,
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              const isNewPlayer = !players.find(
                (player) =>
                  player.label === newValue.label || player.label === newValue
              );

              if (isNewPlayer) {
                const newPlayer = {
                  label: newValue,
                  image: null,
                };
                setSelectedPlayer(newPlayer);
              } else {
                setSelectedPlayer(newValue);
              }
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  fontSize: "15px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                placeholder={`Username or ${val == 1 ? "Name" : "Group"}`}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            );
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              <div className={classes.flexRowContainer}>
                {option && option?.image ? (
                  <CImage src={option?.image} className={classes.image} />
                ) : (
                  <Avatar
                    {...stringAvatar(option?.label, "40px", "20px")}
                    // sx={{ width: "40px", height: "40px", objectFit: "cover" }}
                  />
                )}
                {/* <CImage
                  src={option?.image || Avatar}
                  className={classes.image}
                />{" "} */}
                <span style={{ fontSize: 13 }}>
                  {option.label} {option.type === "group" && "(Group)"}
                </span>
              </div>
            </li>
          )}
          freeSolo={val === 1}
        />
        <button
          className={classes.continue}
          style={{
            background: theme?.primary_color, // Use primary_color from theme
            borderColor: theme?.primary_color, // Optional: apply border color too
          }}
          onClick={() => {
            onAction(selectedPlayer);
            setSearchQuery("");
            setSelectedPlayer(null);
          }}
          disabled={disabled || !selectedPlayer}>
          {ActionButtonText}
        </button>
      </div>
      {disabled && <div className={classes.disabledText}>{disabledText}</div>}
    </>
  );
}
